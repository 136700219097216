<template>
  <div class="register">
    <uregister_third/>
  </div>
</template>

<script>
// @ is an alias to /src
import uregister_third from "@/components/uregister_third";

export default {
  name: "register_third",
  components: {
    uregister_third
  }
};
</script>